import React, { useEffect } from 'react';
import cn from 'classnames';
import { scroller } from 'react-scroll';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, SvgIcon } from '@ui-elements';
import { PostcodeDisclaimer } from '~/common/components';
import { ColourOptions } from '../colours-options';
import { InteriorOptions } from '../interior-options';
import { VariantIntroText } from '../variant-intro';
import { VariantCarousel } from '../variant-carousel';
import { CompareRangePriceDetail } from '~/pages/model-landing/components/model-landing-price-info';
import {
  VariantPageState,
  VariantPageVehicleModelSelector,
  VariantPageVariantSelector,
  ActiveCarouselImageAssetState,
} from '../../store';
import styles from './index.module.scss';

export const VariantInfo = React.memo(() => {
  const [state] = useRecoilState(VariantPageState);
  const [activeImageAsset] = useRecoilState(ActiveCarouselImageAssetState);
  const model = useRecoilValue(VariantPageVehicleModelSelector);
  const variant = useRecoilValue(VariantPageVariantSelector);
  const [tabActive, setTabActive] = React.useState<string>('EX');

  useEffect(() => {
    if (activeImageAsset.assetType === 'Exterior') setTabActive('EX');
    if (activeImageAsset.assetType === 'Interior') setTabActive('IN');
  }, [activeImageAsset]);

  return (
    <div className={styles.variantInfo}>
      <div className={styles.divider}>
        <div className={styles.modelName}>
          <img
            className={styles.modelBadge}
            src={model?.modelBadgeUrl}
            alt={model?.modelName}
          />
          <h2 className={styles.fullVariantName}>{variant?.seriesName}</h2>
        </div>

        <VariantCarousel isMobile />
      </div>

      <div className={cn(styles.divider, styles.bottomSpace, styles.pricing)}>
        <CompareRangePriceDetail
          type="RDP"
          modelName={variant?.shortName}
          variantId={variant?.legacyId}
          pimIdentifier={variant?.pimIdentifier}
          isFinanceFormInputPersonalised={false}
          containerClass={styles.rdpContainer}
          recommendedRdpLabelClass={styles.recommendedRdpLabelClass}
          recommendedRdpLabel="Recommended Driveaway Price"
        />
        <CompareRangePriceDetail
          type="Finance"
          variantId={variant?.legacyId}
          pimIdentifier={variant?.pimIdentifier}
          isFinanceFormInputPersonalised={false}
          containerClass={styles.financeContainer}
          labelClass={styles.priceLabelClass}
          priceClass={styles.priceClass}
        />
      </div>

      <PostcodeDisclaimer className={styles.bottomSpace} />

      {!!state.offerLists && state.offerLists.length > 0 && (
        <div className={styles.offers}>
          <div>
            <SvgIcon type="horn" fill="#1637A0" color="#1637A0" />
            <p>Offers</p>
          </div>
          <div className={styles.offersList}>
            {state.offerLists.map((offer, index) => (
              <a key={index} href={offer.url} target={offer.target}>
                {offer.name}
              </a>
            ))}
          </div>
        </div>
      )}

      <div className={styles.mobileTabs}>
        <div
          className={cn({ [styles.tabActive]: tabActive === 'EX' })}
          onClick={() => setTabActive('EX')}
        >
          Colours
        </div>
        <div
          className={cn({ [styles.tabActive]: tabActive === 'IN' })}
          onClick={() => setTabActive('IN')}
        >
          Interiors
        </div>
        <div
          className={styles.accessoriesTab}
          onClick={() =>
            scroller.scrollTo('variant-accessory-packs', {
              smooth: true,
              offset: -90,
              ignoreCancelEvents: true,
            })
          }
        >
          <span>Accessories</span>
          <SvgIcon type="chevronRight" style={{ marginLeft: '10px' }} />
        </div>
      </div>

      <ColourOptions
        className={cn(styles.tabContent, {
          [styles.tabContentActive]: tabActive === 'EX',
        })}
      />

      <InteriorOptions
        className={cn(
          styles.divider,
          styles.bottomSpace,
          styles.interiorContainer,
          styles.tabContent,
          {
            [styles.tabContentActive]: tabActive === 'IN',
          }
        )}
      />

      <VariantIntroText introText={state.introText} />

      <div className={styles.bottomSpace}>
        {state.ctas &&
          state.ctas.map((cta, index) => (
            <div
              key={index}
              className={styles.ctaButtonWrapper}
              style={{ textAlign: cta.ctaButtonAlignment }}
            >
              <Button
                buttonSize={cta.ctaButtonSize}
                type={cta.ctaButtonType}
                href={cta.ctaUrl?.name}
                target={cta.ctaUrl?.target}
                buttonWidth={cta.ctaFullWidth}
              >
                {cta.ctaUrl?.name}
              </Button>
            </div>
          ))}
      </div>
    </div>
  );
});
